@use 'sass:math';
$color: white;
$arrow-color: #e7e3df;
@import '../../../../theme/size';

.grid {
  transition: 0.1s;
}

.mt20 {
  margin-top: 20px;
}

.radial_progress {
  $circle-size: 60px;
  $inset-size: 45px;
  $transition-length: 2s;

  margin: 0;
  width: $circle-size;
  height: $circle-size;

  border-radius: 50%;

  .circle {
    .mask,
    .fill {
      width: $circle-size;
      height: $circle-size;
      position: absolute;
      border-radius: 50%;
    }

    .mask,
    .fill {
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      transition: -webkit-transform $transition-length;
      transition: -ms-transform $transition-length;
      transition: transform $transition-length;
      border-radius: 50%;
    }

    .mask {
      clip: rect(0px, $circle-size, $circle-size, math.div($circle-size,2));

      .fill {
        clip: rect(0px, math.div($circle-size,2), $circle-size, 0px);
      }
    }
  }

  .inset {
    width: $inset-size;
    height: $inset-size;
    position: absolute;
    margin-left: math.div($circle-size - $inset-size,2);
    margin-top: math.div($circle-size - $inset-size,2);
    border-radius: 50%;
  }

  $increment: math.div(180deg,100);
  @for $i from 0 through 100 {
    &[data-progress='#{$i}'] {
      .circle {
        .mask.full,
        .fill {
          -webkit-transform: rotate($increment * $i);
          -ms-transform: rotate($increment * $i);
          transform: rotate($increment * $i);
        }
      }
    }
  }
}

@import '../../../../theme/size';

// PHONE SCREEN
@media only screen and (max-width: $SCREEN_WIDTH_S) {
  .article_page_insight {
    width: 120% !important;
  }

  .grid {
    width: 100% !important;
  }

  .colored_background {
    width: 100%;
  }
  .long_title {
    width: 100%;
  }
  .short_title {
    width: 100%;
  }
  .item {
    width: calc(100% + 96px);
    margin-left: -48px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .article_image_box {
    width: 100%;
  }
  .add_idea_box {
    margin-left: 400px;
  }
  .article_card_box_short {
    height: 400px;
    width: 100%;
  }
  .article_card_box_tall {
    height: 1000px;
    width: 783px;
  }
  .new_user_idea {
    width: 100%;
  }
  .logged_in_save_box {
    margin-right: 0;
  }
  .logged_out_save_box {
    margin-right: 0;
  }
  .preview_ideas_box {
    margin-left: 400px;
  }
  .arrow_right,
  .arrow_left {
    width: 0;
    height: 35px;
    border: 3px solid red;
    position: relative;
    margin-top: 8px;
    margin-bottom: 15px;

    .timeline_point {
      position: absolute;
      top: -10px;
      transform: translateX(-50%);
      height: 15px;
      width: 15px;
      border-radius: 100%;
      background: $arrow-color;
    }

    .card_point {
      position: absolute;
      bottom: -10px;
      transform: translateX(-50%);
      height: 15px;
      width: 15px;
      border-radius: 100%;
      background: $arrow-color;
    }
  }
  .article_info_box {
    width: 100%;
  }

  .book_decription_and_title {
    margin-top: 16px;
    text-align: left;
    width: 100%;
  }

  .flex_fit {
    flex-direction: column;
  }
}

// COMPUTER SCREEN
@media only screen and (min-width: $SCREEN_WIDTH_L) {
  .article_page_insight {
    width: $IDEA_WIDTH;
  }

  .colored_background {
    width: 95%;
  }
  .long_title {
    width: 2 * $IDEA_WIDTH - $PAGE_PADDING;
  }
  .short_title {
    width: $IDEA_WIDTH - $PAGE_PADDING;
  }
  .item {
    width: $IDEA_WIDTH;
  }
  .article_image_box {
    // width: 2 * $SMALL_INSIGHT_CARD_WIDTH;
  }
  .preview_ideas_box {
    margin-top: $PAGE_PADDING + 5px;
    margin-left: $PAGE_PADDING;
    flex-direction: row;
    height: $IDEA_WIDTH;
  }
  .article_card_box_short {
    height: 400px;
    width: $SAVED_IDEAS_WIDTH;
  }
  .article_card_box_tall {
    height: 460px;
    width: $SAVED_IDEAS_WIDTH;
  }
  .book_card_box_tall {
    height: 370px;
    width: $IDEA_WIDTH - $PAGE_PADDING;
  }
  .book_card_box_short {
    height: 310px;
    width: 2 * $IDEA_WIDTH - $PAGE_PADDING;
  }
  .new_user_idea {
    width: $IDEA_WIDTH;
  }
  .logged_in_save_box {
    margin-right: 0;
  }
  .logged_out_save_box {
    margin-right: $RIGHT_SIDE_WIDTH + 100px;
  }

  .arrow {
    position: relative;
    height: 0;
    opacity: 0;
    border-top: 3px solid $arrow-color;

    .timeline_point {
      position: absolute;
      top: -10px;
      height: 20px;
      width: 20px;
      border-radius: 100%;
      background: $arrow-color;
    }

    .card_point {
      position: absolute;
      bottom: -10px;
      height: 20px;
      width: 20px;
      border-radius: 100%;
      background: $arrow-color;
    }
  }
  .arrow_left,
  .arrow_right {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .arrow_left {
    height: 20px;
    margin-right: -25px;
    margin-left: 50%;
    opacity: 0.3;

    justify-content: flex-end;
    align-items: flex-start;
    border-left: 3px solid $arrow-color;

    .timeline_point {
      right: -10px;
    }

    .card_point {
      left: -11.5px;
    }
  }
  .arrow_right {
    height: 20px;
    margin-left: -25px;
    margin-right: 50%;
    opacity: 0.3;

    justify-content: flex-start;
    align-items: flex-start;
    border-right: 3px solid $arrow-color;

    .timeline_point {
      left: -10px;
    }

    .card_point {
      right: -11.5px;
    }
  }

  .first_arrow {
    margin-top: 50px;
  }

  .flex_fit {
    flex-direction: row;
  }

  .book_decription_and_title {
    justify-content: center;
  }
}

//Actual phone screen

@media only screen and (max-width: 425px) {
  .article_page_insight {
    width: 140% !important;
  }

  .flex_fit {
    flex-direction: column;
  }
}
