// SCREEN SIZES
$SCREEN_WIDTH_S: 870px;
$SCREEN_WIDTH_L: 1220px;

// ------------------------

// FEED HEIGHTS
$FEED_TOP_BLANK: 40px;
$FEED_TOP_BLANK_MOBILE: 70px;
$HEADER_TOP_BLANK: 125px;
$HEADER_STASH_LOGGED_OUT: 400px;
$RECONNECT_HEIGHT: 550px;
$ARTICLE_HEIGHT: 442px;
$SEPARATOR_HEIGHT: 50px;

// SAVED IDEAS HEIGHTS
$HEADER_SPACE: 80px;

// ------------------------

// LAYOUT WIDTHS
$SIDE_MENU_WIDTH: 300px;
$LEFT_SIDE_WIDTH: $SIDE_MENU_WIDTH;
$RIGHT_SIDE_WIDTH: $SIDE_MENU_WIDTH;
$FEED_WIDTH: 735px;
$CONTENT_WIDTH: 870px;
$SAVED_IDEAS_WIDTH: $CONTENT_WIDTH;
$ARTICLE_WIDTH: $CONTENT_WIDTH;
$DROPDOWN_WIDTH: 280px;
$LOGGED_OUT_AD_WIDTH: 320px;

// ------------------------

// COMPONENT SIZES

// FEED
$CAROUSEL_WIDTH: 870px;
$CAROUSEL_ITEM_WIDTH: 400px;
$CAROUSEL_ITEM_HEIGHT: 400px;

// KNOWLEDGE CARDS
$KNOWLEDGE_CARD_WIDTH: 270px;
$KNOWLEDGE_CARD_HEIGHT: 270px;

// INSIGHT CARDS
$SMALL_INSIGHT_CARD_WIDTH: 130px;
$SMALL_INSIGHT_CARD_HEIGHT: 125px;

// SAVED IDEAS
$IDEA_WIDTH: 415px;
$PREVIEW_IDEA_WIDTH: 279px;
$PREVIEW_IDEA_HEIGHT: 279px;
$HEADER_OVERFLOW: 30px;
$HEADER_OVERFLOW_SCROLLING: 15px;
$HEADER_WIDTH: $CAROUSEL_WIDTH;
$HEADER_WIDTH_SCROLLING: $CAROUSEL_WIDTH + 2 * $HEADER_OVERFLOW_SCROLLING;
$HEADER_WIDTH_SHORT: $FEED_WIDTH + 2 * $HEADER_OVERFLOW;
$HEADER_WIDTH_SHORT_SCROLLING: $FEED_WIDTH + 2 * $HEADER_OVERFLOW;

// TOAST
$TOAST_WIDTH: 600px;

// ------------------------

// STYLING
$BORDER_RADIUS_S: 10px;
$BORDER_RADIUS_M: 15px;
$BORDER_RADIUS_L: 25px;

$MARGIN_S: 10px;
$MARGIN_M: 15px;
$MARGIN_L: 25px;

// PADDING
$CONTENT_PADDING_S: 10px;
$CONTENT_PADDING_M: 15px;
$CONTENT_PADDING_L: 20px;
$PAGE_PADDING: 25px;

// ------------------------

// MISC
$CURTAIN_THRESHOLD: 1500px;
