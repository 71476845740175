* {
  box-sizing: border-box;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  margin: 0;
  position: relative;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayScale;
}

iframe {
  border: none;
}

p,
ul,
ol {
  margin: 0;
}

ul,
ol {
  padding: 0;
}

/*There is one idea from us that has a gif inside a <p> */

p > img {
  width: 100%;
}

ul.user-lists {
  list-style: none;
  margin-left: 20px !important;
}

ol.user-lists {
  list-style: none;
  counter-reset: section;
  margin-left: 20px !important;
}

ul.user-lists-light li {
  font-size: 16px;
  line-height: normal;
}

ol.user-lists-light li {
  font-size: 16px;
  line-height: normal;
}

ul.user-lists-light li::before {
  content: '\2022';
  color: #743c7a;
  font-weight: bold;
  display: inline-block;
  width: 20px;
  font-size: 30px;
  margin-left: -20px;
  margin-top: -10px;
  position: absolute;
}

ul.user-lists-dark li::before {
  content: '\2022';
  color: #b786bb;
  font-weight: bold;
  display: inline-block;
  width: 20px;
  font-size: 30px;
  margin-left: -20px;
  margin-top: -10px;
  position: absolute;
}

ol.user-lists-light {
  counter-reset: section;
}

ol.user-lists-light li::before {
  counter-increment: section;
  content: counter(section) '.';
  color: #743c7a;
  font-weight: bold;
  display: inline-block;
  width: 20px;
  font-size: 20px;
  margin-left: -30px;
}

ol.user-lists-dark li::before {
  counter-increment: section;
  content: counter(section) '.';
  color: #b786bb;
  font-weight: bold;
  display: inline-block;
  width: 20px;
  font-size: 20px;
  margin-left: -20px;
}

.ql-active svg path {
  fill: #79a2d2 !important;
}

.ql-editor ul,
.ql-editor ol {
  padding-left: 0.5em;
}

/* Editor list that overflows length limit */

.overflow-list-item::before {
  color: #d44c3a;
}

/* Make the whole placeholder for the editor-image-picker clickable */
.public-DraftEditor-content {
  min-height: 6.25rem;
}

.idea-content-link {
  color: #743c7a !important;
  text-decoration: underline;
}

.idea-content-link-dark {
  color: #b786bb !important;
  text-decoration: underline;
}

.ds-u-light,
.ds-topic-light,
.ds-internal-link-light,
.ds-dynamic-link-light,
.ds-web-link-light {
  color: #743c7a;
  text-decoration: underline;
  cursor: pointer;
}
.ds-u-dark,
.ds-topic-dark,
.ds-internal-link-dark,
.ds-dynamic-link-dark,
.ds-web-link-dark {
  color: #b786bb;
  text-decoration: underline;
  cursor: pointer;
}

/* Hide (vertical/horizontal) scrollbar for Chrome, Safari and Opera */
.removeScrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide (vertical/horizontal) scrollbar for IE, Edge and Firefox */
.removeScrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #faf7f3 inset; /* Change the color to your own background color */
  -webkit-text-fill-color: #333;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px #faf7f3 inset; /*your box-shadow*/
  -webkit-text-fill-color: #333;
}

.colored-text {
  color: #743C7A;
}